import { IApplicationOrganisation } from 'data-transfers/dto'
import {
  ICreateApplications,
  IGetApplicationsOrganisationRequest,
  IGetApplicationsRequest,
  IUpdateStatusApplicationRequest
} from 'data-transfers/requests'
import {
  IAdvertApplicationResponse,
  IAdvertApplicationWithTotalResponse,
  IApplicationOrganisationResponse,
  ICandidateApplicationResponse,
  ICreateApplicationResponse
} from 'data-transfers/responses'
import { ApplicationStatus } from 'enums'

import { authClient, parseContentRange } from 'utils'

export const createApplication = async (props: ICreateApplications) => {
  const { data } = await authClient.post<ICreateApplicationResponse>(
    `/applications`,
    props
  )

  return data
}

export const getCandidateApplications = async ({
  id,
  languageCode = 'en',
  offset,
  limit,
  order,
  orderingColumn,
  status
}: IGetApplicationsRequest) => {
  const { data, headers } = await authClient.get<ICandidateApplicationResponse>(
    `/applications/adverts/${id}`,
    {
      params: {
        languageCode,
        offset,
        limit,
        order,
        orderingColumn,
        status
      }
    }
  )

  const totalApplication = parseContentRange(headers)?.total

  return {
    ...data,
    totalApplication: !Number.isNaN(totalApplication)
      ? Number(totalApplication)
      : 0
  }
}

export const getAdvertApplications = async ({
  id,
  languageCode = 'en',
  offset,
  limit,
  applicationStatusFilter,
  orderingColumn,
  order
}: IGetApplicationsRequest): Promise<IAdvertApplicationWithTotalResponse> => {
  const { data, headers } = await authClient.get<IAdvertApplicationResponse>(
    `/applications/candidates/${id}`,
    {
      params: {
        languageCode,
        offset,
        limit,
        applicationStatusFilter,
        orderingColumn,
        order
      }
    }
  )

  if (headers && headers['content-range']) {
    return { ...data, total: headers['content-range'].split('/')[1] }
  }

  return data
}

export const getApplicationsOrganization = async ({
  languageCode = 'en',
  offset,
  limit,
  status
}: IGetApplicationsOrganisationRequest): Promise<IApplicationOrganisationResponse> => {
  const { data, headers } = await authClient.get<IApplicationOrganisation[]>(
    `/applications/organisations`,
    { params: { languageCode, offset, limit, status } }
  )

  const total = parseContentRange(headers)?.total ?? 0

  return { applications: data, total }
}

export const updateApplicationStatus = async ({
  applicationId,
  status
}: IUpdateStatusApplicationRequest) => {
  const { data } = await authClient.post<ApplicationStatus>(
    `/applications/${applicationId}/statuses`,
    { status }
  )

  return data
}
