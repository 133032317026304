import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IMatch } from 'data-transfers/dto'
import { ICreateApplications } from 'data-transfers/requests'
import { ICreateApplicationResponse } from 'data-transfers/responses'

import { IUseMutation } from 'hooks/types'
import { advertMatchesKeys } from 'hooks/useMatching/keys'

import { createApplication } from './application.service'

const useCreateApplication = ({
  onSuccess,
  ...rest
}: IUseMutation<ICreateApplicationResponse, unknown, ICreateApplications>) => {
  const queryClient = useQueryClient()
  return useMutation(createApplication, {
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context)

      queryClient.setQueryData<IMatch>(
        advertMatchesKeys.match(variables),
        (qData) =>
          qData
            ? {
                ...qData,
                applicationId: data.applicationId
              }
            : qData
      )
    },
    ...rest
  })
}

export default useCreateApplication
