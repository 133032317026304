import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IUpdateStatusApplicationRequest } from 'data-transfers/requests'
import { ApplicationStatus } from 'enums'

import { IUseMutation } from 'hooks/types'

import { updateApplicationStatus } from './application.service'
import { applicationKeys } from './keys'

const useUpdateStatusApplication = ({
  onSuccess,
  ...options
}: IUseMutation<
  ApplicationStatus,
  unknown,
  IUpdateStatusApplicationRequest
>) => {
  const queryClient = useQueryClient()

  return useMutation(updateApplicationStatus, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(applicationKeys.all)
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useUpdateStatusApplication
