import {
  IGetApplicationsOrganisationRequest,
  IGetApplicationsRequest
} from 'data-transfers/requests'

export const applicationKeys = {
  all: ['applications'] as const,
  candidate_applications: (advertRequest?: IGetApplicationsRequest) =>
    [...applicationKeys.all, 'candidate_applications', advertRequest] as const,
  advert_applications: (advertRequest?: IGetApplicationsRequest) =>
    [...applicationKeys.all, 'advert_applications', advertRequest] as const,
  applications_organisation: (data?: IGetApplicationsOrganisationRequest) =>
    [...applicationKeys.all, 'applications_organisation', data] as const
}
