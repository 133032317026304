import { useQuery } from '@tanstack/react-query'
import { IGetApplicationsOrganisationRequest } from 'data-transfers/requests'
import { IApplicationOrganisationResponse } from 'data-transfers/responses'

import { IUseQueryHookWithPayload } from 'hooks/types'

import { getApplicationsOrganization } from './application.service'
import { applicationKeys } from './keys'

const useApplicationsOrganisation = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  IGetApplicationsOrganisationRequest,
  IApplicationOrganisationResponse
>) => {
  return useQuery(
    applicationKeys.applications_organisation(payload),
    async () => {
      const response = await getApplicationsOrganization(payload)
      return response
    },
    options
  )
}

export default useApplicationsOrganisation
