import { useQuery } from '@tanstack/react-query'
import { IGetApplicationsRequest } from 'data-transfers/requests'
import { ICandidateApplicationResponse } from 'data-transfers/responses'

import { IUseQueryHookWithPayload } from 'hooks/types'

import { getCandidateApplications } from './application.service'
import { applicationKeys } from './keys'

const useCandidateApplications = ({
  payload,
  options
}: IUseQueryHookWithPayload<
  IGetApplicationsRequest,
  ICandidateApplicationResponse
>) => {
  return useQuery(
    applicationKeys.candidate_applications(payload),
    async () => {
      const response = await getCandidateApplications(payload)
      return response
    },
    options
  )
}

export default useCandidateApplications
