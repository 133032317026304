import { AdvertStatus, AdvertTranslation, CandidateStatus } from 'enums'
import { TFunction, TOptions } from 'i18next'

import { IAdData, IUseAdvertTitle } from 'hooks/useAdvertTitle/types'
import { MatchAdvert } from 'hooks/useMatching/types'

import { adLangsCodes } from 'constants/advert'
import { ListItem } from 'validators/listItemValidator'

interface IGetLabelProps {
  enArr?: ListItem[] | null
  ltArr?: ListItem[] | null
  searchId: string
  lang: string
}

const getLabel = ({
  enArr = [],
  ltArr = [],
  searchId,
  lang
}: IGetLabelProps) => {
  switch (lang) {
    case 'en':
      return enArr?.find((pos) => pos.id === searchId)?.value ?? null
    case 'lt':
      return ltArr?.find((pos) => pos.id === searchId)?.value ?? null
    default:
      return null
  }
}

const isRejected = ({
  advertStatus,
  candidateStatus
}: Pick<MatchAdvert, 'advertStatus' | 'candidateStatus'>) =>
  advertStatus === AdvertStatus.Rejected ||
  candidateStatus === CandidateStatus.Rejected

const getAdvertTranslationsCodes = (
  translations?: AdvertTranslation | null
) => {
  if (translations == null) {
    return adLangsCodes[AdvertTranslation.English]
  }

  return adLangsCodes[translations]
}

export interface IGetAdvertTitle extends IUseAdvertTitle {
  ad?: IAdData | null
  brandName?: string
  locale?: string
}

const hiringForClientText = (
  brandName?: string,
  locale: IGetAdvertTitle['locale'] = 'en',
  isHiring?: boolean
) => {
  if (!brandName) {
    return ''
  }
  if (!isHiring) {
    return `, ${brandName}`
  }

  switch (locale) {
    case 'en':
      return `, for ${brandName} client`
    case 'lt':
      return `, ${brandName} klientui`
  }
}

const getAdvertTitle = ({ ad, brandName, locale = 'en' }: IGetAdvertTitle) => {
  if (!ad) {
    return ''
  }
  const localeTitleObj = ad.titles?.find(
    ({ languageCode }) => languageCode === locale
  )
  const { value: localeTitle } = localeTitleObj ?? {}
  const defaultTitle = ad.title

  if (localeTitle || defaultTitle)
    return `${localeTitle || defaultTitle}${hiringForClientText(
      brandName,
      locale,
      ad?.hiringForClient
    )}`

  const localePositionTextObj = ad.positionTexts?.find(
    ({ languageCode }) => languageCode === locale
  )

  const position = localePositionTextObj?.value || ad.position || ''

  return `${position}${hiringForClientText(
    brandName,
    locale,
    ad?.hiringForClient
  )}`
}

interface IGetAdvertSalaryProps {
  t: (key: string, options: TOptions) => string | TFunction
  from: number | null
  to: number | null
  sign?: string
}

const getAdvertSalary = ({
  t,
  from,
  to,
  sign = 'EUR'
}: IGetAdvertSalaryProps) => {
  if (from && to && from === to) {
    return `${from} ${sign}`
  }
  if (from && to) {
    return `${from} - ${to} ${sign}`
  }
  if (from && !to) {
    return `${t('salary.from', { ns: ['ad'] })} ${from} ${sign}`
  }

  if (!from && to) {
    return `${t('salary.upTo', { ns: ['ad'] })} ${to} ${sign}`
  }

  return '-'
}

const getAdvertExtraTranslation = (translation?: AdvertTranslation | null) => {
  if (
    translation == null ||
    translation === AdvertTranslation.EnglishLithuanian
  ) {
    return []
  }

  return adLangsCodes[translation]
}

const adUtils = {
  getAdvertTitle,
  getAdvertSalary,
  getLabel,
  getAdvertTranslationsCodes,
  getAdvertExtraTranslation,
  isRejected
}

export default adUtils
