import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import { ILocale } from 'types/app'
import adUtils from 'utils/adUtil'

import { IUseAdvertTitle } from './types'

const useAdvertTitle = ({
  ad: adValue,
  brandName: brandNameValue = '',
  lang
}: IUseAdvertTitle) => {
  const { locale } = useRouter()
  const getTitle = useCallback(
    ({ ad, brandName }: IUseAdvertTitle) => {
      return adUtils.getAdvertTitle({ ad, brandName, locale: lang || locale })
    },
    [lang, locale]
  )
  const getTitleByLocale = useCallback(
    ({ ad, brandName, locale }: IUseAdvertTitle & { locale: ILocale }) => {
      return adUtils.getAdvertTitle({ ad, brandName, locale })
    },
    []
  )

  const title = useMemo(
    () => getTitle({ ad: adValue, brandName: brandNameValue }),
    [adValue, brandNameValue, getTitle]
  )

  return {
    title,
    getTitle,
    getTitleByLocale
  }
}

export default useAdvertTitle
